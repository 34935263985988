@import '../../../variables.module.scss';

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #cfdef3, #e0eafc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to left,
        #cfdef3,
        #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.container img {
    width: $header-logo-width;
}

.wrapper {
    width: 95%;
    max-width: 800px;
    height: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border-radius: 10px;
}

.card {
    width: min(80vw, 800px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card img {
    width: 60px;
    margin-bottom: 1vw;
}

.footerTag {
    font-size: $footer-p-tag;
}
