.header2-text {
    display: flex;
    align-items: center;
}

.header2-text-label {
    width: 160px;
    text-align: center;
    overflow: hidden;
}

.resource-view > div:nth-child(2) {
    overflow: hidden !important;
}

/* .resource-table tr, th {
    height: 50px;
} */