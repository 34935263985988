@import '../../variables.module.scss';

.inputFieldContainer {
    width: calc(100% - $body-margin - $body-margin);
    margin: $body-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inputField {
    height: 40px;
    display: flex;
    align-items: center;
}

.disabledDatesText {
    margin-top: min(5vw, 20px);
    padding: 0.5vw;
    background-color: #ffeb3b;
    color: black;
    text-align: center;
    letter-spacing: 1px;
}
