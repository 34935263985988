@import '../../variables.module.scss';

.dropdownContainer {
    position: relative;
    width: min(40vw, 300px);
    height: min-content;
    margin: min(3vw, 15px);
}

.dropdownContainer input {
    position: relative;
    padding: 0.5vw;
    width: 100%;
    height: 30px;
    border: 1px solid $light-gray;
    outline: none;
    cursor: pointer;
    z-index: 4;
}

.dropdownItemContainer {
    position: fixed;
    width: min(41vw, 315px);
    max-height: 300px;
    position: absolute;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid $light-gray;
    z-index: 6;
}

.dropdownItemContainer::-webkit-scrollbar {
    display: none;
}

.dropdownItem {
    border-bottom: 1px solid $light-gray;
    cursor: pointer;
    padding: 0.5vw;
}

.dropdownItem:hover {
    background-color: $light-gray;
}

.backDrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
}