@import '../../variables.module.scss';

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    z-index: 8;
    overflow: hidden;
    border-radius: 10px;
}

.header {
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header img {
    padding: 1vw;
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;
}

.centerButton {
    text-align: center;
    border-top: 1px solid $light-gray;
}