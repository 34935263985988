@import '../../variables.module.scss';

.placeholder {
    margin-top: $header-margin-top;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: $header-margin-top;
}

.popUpContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark-gray;
    opacity: 0.5;
    z-index: 7;
}

.inputContainer {
    width: min(95vw, 700px);
}

.searchAndButtonContainer {
    display: flex;
    align-items: flex-end;
}

.centerAlign {
    text-align: center;
}

.searchAndButtonContainer button {
    width: min(35vw, 200px);
    height: min(10vw, 53px);
    font-size: min(3.5vw, 20px) !important;
}