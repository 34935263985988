@import '../../../variables.module.scss';

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #cfdef3, #e0eafc); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to left,
        #cfdef3,
        #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.container img {
    width: $header-logo-width;
}

.wrapper {
    width: 95%;
    height: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border-radius: 10px;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card:nth-child(2) {
    align-self: flex-start;
}

.footerTag {
    font-size: $footer-p-tag;
}

.tracker {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #bbb;
    font-weight: 600;
    width: 100px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
    background-color: #bbb;

    &.active .circle {
        background-color: #3498db; // Active step color
    }
}

.label {
    height: 1vw;
    text-align: center;
    font-size: 11.5px;
    color: black;
    line-height: 1vw;
}

.labelWrapper {
    display: flex;
    flex-direction: column;
}
